



<template>
  <div class="home-account">
    <div v-if="loading.research == 0" class="text-center">
      <img src="https://staging.luxresearchinc.com/assets/render_async_preloader-52243b3c41782a6e3a15630546defb42df510748a9126bdd24334f9a30ad55bd.gif" />
    </div>
    <h3 v-if="loading.research == 1" ref="myResearch" class="heading">
      My Lux Research
    </h3>
    <el-divider v-if="loading.research == 1" />

    <template v-for="(list, research) in researchList">
      <el-row :key="`${research}-title`" class="home-following">
        <el-col v-if="list.length == 0" :span="20">
          {{ research }}
        </el-col>
        <el-col v-else :span="20">
          <strong>{{ research }}</strong><br>
        </el-col>
        <el-col v-if="['Inquiries', 'My Tags', 'Saved Searches'].includes(research)" :span="4" class="text-center">
          <span :class="`following-count ${list.length > 0 ? '' : 'empty'}`">
            {{ list.length }}
          </span>
          <i
            v-if="howToList.includes(research)"
            class="el-icon-circle-plus-outline"
            @click="() => jumpTo(research)"
          />
        </el-col>
      </el-row>
      <el-row v-if="list.length > 0" :key="`${research}-list`">
        <el-col :span="24">
          <ul class="home-list">
            <li
              v-for="(item, idx) in list"
              :key="item.name"
              v-if="idx < 5"
              :class="research | iconify"
            >
              <a v-if="item.href" :href="item.href">{{ item.name.substring(0, 25).trim() }}{{ item.name.length > 25 ? '...' : '' }}</a>
              <div
                v-if="research == 'Inquiries' && item.status"
                class="inquiry-status"
              >
                <el-tooltip placement="right" :content="item.status | capitalize">
                  <span :class="`status-${item.status}`"></span>
                </el-tooltip>
              </div>
              <div v-if="research == 'My Tags'" class="tag-count text-center">
                {{ item.count }}
              </div>
            </li>
            <li :class="research | iconify">
              <span>
                <span v-if="list.length > 5">...plus <strong>{{ list.length - 5 }}</strong> others </span><a :href="`/mylux#${myLux[research]}`">(see more)</a>
              </span>
            </li>
          </ul>
        </el-col>
      </el-row>
    </template>

<!--

 This will be surpressed until clients are fully staffed and accounts are fully transitioned,
  for this reason this is just commented because it's a temporary change 

 <h3 v-if="loading.account == 1" ref="myTeam" class="heading">
      My Account Team
    </h3>
    <el-divider v-if="loading.account == 1" />

    <template v-if="loading.account == 1">
      <el-row v-for="(manager, role) in managers" :key="role" class="pad-top-10">
        <el-col :span="20">
          <span>My {{ role }}:</span><br>
          <a v-if="manager" :href="`mailto:${manager.email}`" class="manager-email">
            <span v-if="manager.first_name || manager.last_name">{{ manager | name }}</span>
            <span v-else>{{ manager.email }}</span>
            <i class="el-icon-message" />
          </a>
          <span v-else>N/A</span>
        </el-col>
        <el-col v-if="manager && manager.headshot" :span="4" class="text-center">
          <el-avatar :src="manager.headshot" :alt="manager | name">
            <i class="el-icon-user-solid" />
          </el-avatar>
        </el-col>
      </el-row>
    </template>

    <div v-if="loading.following == 0" class="text-center">
      < %= helpers.image_tag 'render_async_preloader.gif' %>
    </div> -->
    <h3 v-if="loading.following == 1" class="heading">
      My Follows
    </h3>
    <el-divider v-if="loading.following == 1" />

    <el-row v-for="(list, follow) in followList" :key="follow" class="home-following">
      <el-col v-if="list.length == 0" :span="20">
        {{ follow }}
      </el-col>
      <el-col v-else :span="20">
        <strong>{{ follow }}</strong><br>
        <ul class="home-list">
          <li
            v-for="(item, idx) in list"
            :key="item.name"
            v-if="idx < 5"
            :class="follow | iconify"
          >
            <a :href="item.href">{{ item.name.substring(0, 25).trim() }}{{ item.name.length > 25 ? '...' : '' }}</a>
          </li>
          <li :class="follow | iconify">
            <span>
              <span v-if="list.length > 5">...plus <strong>{{ list.length - 5 }}</strong> others </span><a :href="`/mylux#${myLux[follow]}`">(see more)</a>
            </span>
          </li>
        </ul>
      </el-col>
      <el-col :span="4" class="text-center">
        <span :class="`following-count ${list.length > 0 ? '' : 'empty'}`">
          {{ list.length }}
        </span>
        <i
          v-if="howToList.includes(follow)"
          class="el-icon-circle-plus-outline"
          @click="() => jumpTo(follow)"
        />
      </el-col>
    </el-row>

    <h3 class="heading">
      How To Personalize Lux
    </h3>
    <el-divider />

    <p ref="howToTechnologies" class="home-list">
      <strong>To follow a technology:</strong>
      <ul>
        <li>Browse for technologies you're interested in with the <a target="_blank" href="/research/technology">Lux Tech 299</a></li>
        <li>Click the <span class="glyphicon glyphicon-heart"></span> icon next to the name of any technology in the 299 list</li>
        <li>Or click the <strong><span class="glyphicon glyphicon-heart"></span> Follow Technology</strong> icon on any individual technology's page</li>
      </ul>
    </p>

    <p ref="howToCompanies" class="home-list">
      <br><strong>To follow a company:</strong>
      <ul>
        <li>Search for companies you're interested in with the <a target="_blank" href="/search/companies">Company Search</a></li>
        <li>Click the <strong><span class="glyphicon glyphicon-heart"></span> Follow</strong> icon on any individual company's page</li>
      </ul>
    </p>

    <p ref="howToSearches" class="home-list">
      <br><strong>To follow a saved search:</strong>
      <ul>
        <li>
          Start with one of our search pages:
          <a target="_blank" href="/search">General</a>,
          <a target="_blank" href="/search/companies">Companies</a>,
          <a target="_blank" href="/search/news">News</a>,
          <a target="_blank" href="/search/technologies">Technology</a>
        </li>
        <li>Type keywords and apply filters until the results meet your needs</li>
        <li>Click the <strong>Save Search</strong> button at the top right of the page (left side for <strong>News</strong>)</li>
        <li>Give your search a name and click <strong>Save Search</strong></li>
        <li>You can modify your search later by clicking it in the <strong>Me</strong> menu, adjusting it, and clicking <strong>Save Search</strong> again</li>
      </ul>
    </p>

    <p class="following-guide">
      <br>You can manage your followed content in the <strong>My Notifications</strong> section of the <strong>Me</strong> menu.
    </p>

    <p class="following-guide">
      For help with any of these, don't hesitate to contact your client engagement manager.
    </p>
  </div>
</template>

<script>
  import {checkResponse} from 'labs-js/src/common/utils'

  export default {
    filters: {
      name: user => {
        return user.first_name + ' ' + user.last_name
      },
      startYear: user => {
        return user.created_at.substring(0, 4)
      },
      iconify: following => {
        return `lux-icon icon-${following.split(' ').pop().toLowerCase()}`
      }
    },
    data() {
      return {
        user: {},
        am: {},
        cem: {},
        research: {},
        following: {},
        myLux: {
          'Followed Technologies': 'followed-technologies',
          'Followed Companies': 'followed-companies',
          'Saved Searches': 'saved-searches',
          'Recent Searches': 'search-history',
          'Inquiries': 'inquiries',
          'Reading History': 'reading-history',
          'My Tags': 'tags'
        },
        howToList: [
          'Followed Technologies',
          'Followed Companies',
          'Saved Searches',
        ],
        loading: {
          'account': 0,
          'research': 0,
          'following': 0,
        },
        apiErrs: 0,
      }
    },
    props: {
      showInquiry: Boolean
    },
    computed: {
      researchList() {
        var list = {}
        if (this.showInquiry) list['Inquiries'] = this.research.inquiries || []
        list['My Tags'] = this.research.tags || []
        list['Reading History'] = this.research.reading || []
        list['Recent Searches'] = this.research.searches || []
        list['Saved Searches'] = this.research.saved || []
        return list
      },
      followList() {
        return {
          'Followed Companies': this.following.companies || [],
          'Followed Technologies': this.following.technologies || [],
        }
      },
      managers() {
        return {
          'Account Manager': this.am,
          'Engagement Manager': this.cem
        }
      },
    },
    watch: {
      apiErrs (val) {
        if (val == 3) this.$emit('hide')
      }
    },
    created() {
      fetch("/home/account.json")
        .then(checkResponse)
        .then(data => {
          this.user = data.user
          this.am = data.am
          this.cem = data.cem
          this.loading.account = 1
          this.$emit('step-ready')
        })
        .catch(err => {
          this.loading.account = -1
          this.apiErrs++
        })
      fetch("/home/research.json")
        .then(checkResponse)
        .then(data => {
          this.research = data.research

          this.loading.research = 1
          this.$emit('step-ready')
        })
        .catch(err => {
          this.loading.research = -1
          this.apiErrs++
        })
      fetch("/home/following.json")
        .then(checkResponse)
        .then(data => {
          this.following = data.following
          this.loading.following = 1
        })
        .catch(err => {
          this.loading.following = -1
          this.apiErrs++
        })
    },
    methods: {
      jumpTo(target) {
        const refName = `howTo${target.split(' ').pop()}`
        this.$refs[refName].scrollIntoView({behavior: 'smooth'})
      },
    },
  }
</script>

<style lang="scss">
  @import "app/assets/stylesheets/common";

  .home-account {
    color: #5d5d5d;

    .home-account-header {
      color: $lux-blue;
      text-transform: uppercase;
      font-weight: 700;
    }

    .home-following {
      i { cursor: pointer; }

      .following-count {
        color: $lux-blue;
        font-weight: 700;

        &.empty { color: grey; }
      }

      & ~ .home-following { margin-top: 10px; }
    }

    .home-list {
      &, ul { margin-bottom: 0; }

      li {
        margin: 0;
        font-size: 12px;
        line-height: normal;

        &.lux-icon {
          list-style: none;

          &:before {
            content: '';
            display: inline-block;
            height: 1em;
            width: 20px;
            margin-left: -20px;
            background-size: contain;
            background-repeat: no-repeat;
            -ms-filter: grayscale(1) brightness(50%);
            -webkit-filter: grayscale(1) brightness(50%);
            -moz-filter: grayscale(1) brightness(50%);
            -o-filter: grayscale(1) brightness(50%);
            filter: grayscale(1) brightness(50%);
          }

          &.icon-technologies:before {
            background-image: url('https://staging.luxresearchinc.com/assets/new/large_tech_icon-60ed573a64c61d129fa01f34824f13c546611465ce63eada7c38c9a5522e782d.svg');
          }
          &.icon-companies:before {
            background-image: url('https://staging.luxresearchinc.com/assets/new/large_companies_icon-a6a498edae3f0bc945bab0c05aee45a40ee6fa1ffc8aa9026bcc86d09a2e4ad4.svg');
          }
          &.icon-inquiries:before {
            background-image: url('https://staging.luxresearchinc.com/assets/new/large_analyst_icon-046080515f78568f609aeffb7d506add8a2b38240affd4341ba21bc28a61e638.svg');
          }
          &.icon-searches:before {
            content: '\e003'; /* glyphicon-search */
            font-family: 'Glyphicons Halflings';
            -ms-filter: none;
            -webkit-filter: none;
            -moz-filter: none;
            -o-filter: none;
            filter: none;
          }
          &.icon-history:before {
            content: '\e030'; /* glyphicon-repeat */
            font-family: 'Glyphicons Halflings';
            -ms-filter: none;
            -webkit-filter: none;
            -moz-filter: none;
            -o-filter: none;
            filter: none;
          }
          &.icon-tags:before {
            content: '\e041'; /* glyphicon-tag */
            font-family: 'Glyphicons Halflings';
            -ms-filter: none;
            -webkit-filter: none;
            -moz-filter: none;
            -o-filter: none;
            filter: none;
          }
        }

        &.icon-inquiries,
        &.icon-tags {
          display: flex;
          padding-top: 2px;
          &:before {flex: 0 0 20px;}

          a {flex-basis: calc((500% + 100px) / 6);}

          .inquiry-status, .tag-count {
            flex-basis: calc((100% + 20px) / 6);
            flex-grow: 0;
            flex-shrink: 0;

            span {
              height: 12px;
              width: 12px;
              border-radius: 50%;
              display: block;
              margin: 0 auto;
              background-color: #ddd;

              &.status-open {background-color: #fff; border: 2px solid $accent-green;}
              &.status-answered {background-color: $accent-green;}
              &.status-closed {background-color: $lux-red;}
            }
          }
        }
      }
    }

    .manager-email {
      font-weight: 700;
    }
  }
</style>
