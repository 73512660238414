<template>
  <div class="home-research">
    <span v-if="user.length && source == 'recommended'" ref="researchPreamble" class="research-preamble">
      Recommended for you, <strong>{{ user }}</strong>,
      based on your reading history and any followed companies, technologies, or saved searches:
    </span>
    <el-divider id="research-toggle-divider" content-position="right">
      <el-dropdown @command="switchSource">
        <span class="research-toggle">
          Sort by: <strong class="research-source">{{ source }}</strong>
          <i class="el-icon-caret-bottom el-icon--right"></i>
        </span>
        <el-dropdown-menu>
          <el-dropdown-item v-if="user.length" command="recommended">Recommended</el-dropdown-item>
          <el-dropdown-item command="recent">Recent</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-divider>
    <div :class="researchTab('recent')">
      <recent-research />
    </div>
    <div v-if="user.length" :class="researchTab('recommended')">
      <recommended-research
        @has-recommendations="hasRecommendations"
      />
      <el-button
        size="medium"
        class="btn-luxButton btn-recent"
        @click="showRecent"
      >
        See All Recent Research for More Content
      </el-button>
    </div>
  </div>
</template>

<script>
  import RecentResearch from './RecentResearch'
  import RecommendedResearch from './RecommendedResearch'

  export default {
    components: {
      RecentResearch,
      RecommendedResearch
    },
    props: {
      user: {type: String, default: ''}
    },
    data() {
      return {
        source: null
      }
    },
    created() {
      if (localStorage.researchListsSource == 'upcoming') localStorage.removeItem('researchListsSource')
      this.source = localStorage.researchListsSource || (this.user.length ? 'recommended' : 'recent')
    },
    methods: {
      switchSource(source) {
        this.source = source
        localStorage.researchListsSource = source
      },
      switchFromSource(from, to) {
        if (this.source == from) this.switchSource(to)
      },
      researchTab(source) {
        return `research-tab ${this.source === source ? 'active' : ''}`
      },
      showRecent() {
        this.switchSource('recent')
        window.scrollTo({top: 0, behavior: 'smooth'})
      },
      hasRecommendations(value) {
        if (value) {
          this.$emit('step-ready')
        } else {
          this.switchFromSource('recommended', 'recent')
        }
      }
    }
  }
</script>

<style lang="scss">
  .home-research {
    .research-preamble, .research-toggle { color: #5d5d5d; }
    .research-source { text-transform: capitalize; }
    .btn-recent {
      margin: 30px auto 0;
      display: block;
    }

    #research-toggle-divider {
      margin: 15px 0;

      .el-divider__text { background-color: #f5f5f5;}
    }

    .research-tab {
      display: none;

      &.active { display: block; }
    }
  }
</style>
