
<template>
  <div v-if="trending.length" class="home-trending">
    <h3 class="heading">Trending Research</h3>
    <el-divider />
    <span>What your innovation peers are reading most on the Lux member site:</span><br>
    <el-row
      v-for="(hit, idx) in trending"
      :key="idx"
      v-if="displayAll || idx < 5"
      class="trending-item"
    >
      <el-col :span="2" class="trending-count">{{ idx + 1 }}.</el-col>
      <el-col :span="22">
        <a class="trending-link" :href="hit.href">{{ hit.title }}</a><br>
        <span>{{ hit.type }} | {{ hit.date }}</span>
      </el-col>
    </el-row>
    <el-button
      v-if="!displayAll"
      size="medium"
      class="btn-luxButton btn-trending"
      @click="showAll"
    >
      See Rest of Leaderboard
    </el-button>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        trending: [],
        displayAll: false
      }
    },
    created() {
      fetch("/home/trending.json")
        .then(res => res.json())
        .then(data => {
          this.trending = data.trending
        })
    },
    methods: {
      showAll() {
        this.displayAll = true
      }
    }
  }
</script>

<style lang="scss">
  @import "app/assets/stylesheets/common";

  .home-trending {
    color: #5d5d5d;

    .trending-item {
      margin-top: 20px;

      .trending-count {font-weight: 500;}
      .trending-link {
        font-weight: 600;
        color: $lux-blue;
      }
    }

    .btn-trending {
      margin-top: 20px;
    }
  }
</style>
