

<template>
  <div class="recommended-research">
    <div v-if="!hasLoaded" class="text-center">
      <img src="https://staging.luxresearchinc.com/assets/render_async_preloader-52243b3c41782a6e3a15630546defb42df510748a9126bdd24334f9a30ad55bd.gif" />
    </div>
    <div v-else>
      <template v-if="recommended.length">
        <research-item
          v-for="item in recommended"
          :key="item.title"
          :item="item"
        />
      </template>
      <template v-else>
        <p class="keywords"><strong>You're not currently following any content.</strong></p>
        <p class="keywords">
          This personalized feed of relevant content is based on your followed companies, followed technologies, saved searches, and your Lux Research reading history.
          To start seeing some personalized recommendations, please start reading some of the content on our website, or follow research items you are interested in, or create a saved search.
        </p>
      </template>
    </div>
  </div>
</template>

<script>
  import ResearchItem from './ResearchItem'

  const url = "/home/recommended.json"

  export default {
    components: {
      ResearchItem
    },
    data() {
      return {
        recommended: [],
        hasLoaded: false,
      }
    },
    created() {
      fetch(url)
        .then(res => res.json())
        .then(data => {
          this.recommended = data.recommended
          this.hasLoaded = true
        })
    },
    watch: {
      recommended: function () {
        this.$emit('has-recommendations', !!this.recommended.length)
      }
    }
  }
</script>
