
<template>
  <div class="featured-updates">
    <h3 class="heading">Featured Research and Product Updates</h3>
    <el-divider />
    <el-carousel v-if="user" trigger="click" :autoplay="false" v-bind="carousel">
      <el-carousel-item v-for="(update, idx) in updates" :key="idx">
        <div class="featured-slider-item">
          <a :href="update.href" :target="update.target">
            <img class="featured-slider-bg" :src="update.src"  />
          </a>
        </div>
      </el-carousel-item>
    </el-carousel>

    <!-- don't show arrows or indicators when there's only one carousel item -->
    <el-carousel
      v-else
      height="300px"
      trigger="click"
      :autoplay="false"
      arrow="never"
      indicator-position="none"
    >
      <el-carousel-item>
        <div class="featured-slider-item">
          <div class="featured-slider-bg" id="particlesBg" style="filter:none;"></div>
          <a href="http://www.luxresearchinc.com/about-us/the-lux-difference" target="_blank">
            <div class="featured-slider-info">
              <h1>Innovate Smarter &amp; Grow Faster</h1>
              <div class="particle-fg">
                <h2>Learn more about who we are and what we do</h2>
              </div>
            </div>
          </a>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
  import 'particles.js'
  import particles from '../particles.json'

  export default {
    props: {
      user: {type: String, default: ''}
    },
    data() {
      return {
        updates: [{
          src: 'https://staging.luxresearchinc.com/assets/promotions/Lux-Ideathon-Battery-Recycling-12535e03a4129f625f18b99e08c42aca9f1c1ea13cf55134a804f39ddb5dc304.jpg',
          href: 'https://www.luxresearchinc.com/events-and-webinars/events/ideathon-battery-recycling/',
          target: '_blank'
        }]
      }
    },
    computed: {
      carousel() {
        let features = this.updates.length > 1 ? {
          indicatorPosition: 'outside'
        } : {
          indicatorPosition: 'none',
          arrow: 'never'
        }
        return features
      },
    },
    mounted() {
      if (!this.user) {
        this.$nextTick(() => {
          particlesJS('particlesBg', particles)
        })
      }
    }
  }
</script>

<style lang="scss">
  @import "app/assets/stylesheets/common";

  .featured-updates {
    &:first-child {
      .heading { margin-top: 0; padding-top: 0; }
    }

    .el-carousel {

      max-width: 300px;

      .el-carousel__container {
        @media (max-width: 1200px) {
          height: 243px;
        }
        @media (max-width: 991px) {
          height: 300px;
        }
      }
    }
    ul.el-carousel__indicators li {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .featured-slider-item {
      width: 100%;
      position: relative;

      a {text-decoration: none;}
      .featured-slider-bg {
        width: 100%;
        height: 100%;
      }

      .featured-slider-info {
        color: #fff;
        max-width: 80%;
        margin: 0 auto;
        padding-top: 5px;

        h1 {
          font-size: 30px;
        }

        h2 {
          margin-top: 0;
          font-size: 15px;
        }

        h3 strong {
          font-size: 14px;
          background-color: $accent-yellow;
          padding: 5px 15%;
          margin-left: -15%;
          text-transform: uppercase;
        }

        img { width: 100%; }

        .featured-webinar-divider {
          height: 3px;
          background-color: #fff;
        }

        &.covid-info {
          h1 {
            color: #000;
          }
          h2 {
            color: #fff;
          }
        }
      }

      .particle-fg {
        margin-top: 30px;
        margin-left: 20%;
        margin-right: -15%;
        padding: 7px;
        padding-right: 15%;
        background-color: $accent-green;
      }
    }
  }
</style>
