<template>
  <div class="home-webinar">
    <h3 class="heading">Join Our Next Webinar <i class="el-icon-data-analysis"></i></h3>
    <el-divider />
    <el-row class="webinar-item">
      <el-col>
        <h4 class="webinar-title">
          <a :href="href">
            {{ title }} (<b>Register Here</b>)
          </a>
        </h4>
        <p class="webinar-date">{{ date }}</p>
        <p class="webinar-description">
          {{ description | short }}
          <a :href="href">read more</a>
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    filters: {
      short: paragraph => {
        const pSplit = paragraph.split(/\s+/)
        if (pSplit.length < 23) {
          return paragraph
        } else {
          return pSplit.slice(0, 23).join(' ') + ' ..'
        }
      }
    },
    props: {
      title: {type: String, default: ''},
      date: {type: String, default: ''},
      description: {type: String, default: ''},
      href: {type: String, default: ''},
    },
  }
</script>

<style lang="scss">
  .home-webinar {
    .webinar-item {
      margin-bottom: 20px;

      .webinar-title {
        font-size: 14px;
      }

      .webinar-date {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 14px;
      }

      .webinar-description {
        margin-bottom: 0;
      }
    }
  }
</style>
