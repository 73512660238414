<template>
  <el-popover
    placement="bottom-start" v-model="popoverVisible" >
    <div class="filter-popover">
      <el-row :gutter="20">
        <el-col v-if="includeTypes" :span="coverageAreas.length ? (serviceAreas.length ? 12 : 9) : 24">
          <h5 class="filter-header">Research Types</h5>
          <el-checkbox-group v-model="checkedTypes" size="small" class="filter-checkboxes">
            <template v-for="type in researchTypes">
              <el-checkbox :label="type.id">{{ type.name }}</el-checkbox><br>
            </template>
          </el-checkbox-group>
        </el-col>
        <el-col v-if="coverageAreas.length" :span="includeTypes ? (serviceAreas.length ? 12 : 15) : 24">
          <h5 class="filter-header" v-if="serviceAreas.length">Services</h5>
          <h5 class="filter-header" v-else>Programs &amp; Coverage Areas</h5>
          <div class="filter-checkboxes">
            <el-checkbox-group v-model="checkedAreas" size="small" >
              <template v-if="serviceAreas.length">
                <template v-for="service in serviceAreas">
                  <el-checkbox :label="service.id">{{ service.name }}</el-checkbox><br>
                </template>
              </template>
              <template v-if="programAreas.length && !serviceAreas.length">
                <h5 class="filter-subheader">Programs</h5>
                <template v-for="program in programAreas">
                  <el-checkbox :label="program.id">{{ program.name }}</el-checkbox><br>
                </template>
              </template>
              <template v-if="nonProgramAreas.length && !serviceAreas.length">
                <h5 class="filter-subheader">Coverage Areas</h5>
                <template v-for="area in nonProgramAreas">
                  <el-checkbox :label="area.id">{{ area.name }}</el-checkbox><br>
                </template>
              </template>
            </el-checkbox-group>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <button class="btn btn-default save btn-flatLuxBlue" @click="setFilterParams">Filter</button>
        <button class="btn btn-default save btn-flatLuxBlue" @click="closePopover">Cancel</button>
      </el-row>
    </div>
    <slot slot="reference" />
  </el-popover>
</template>

<script>
  export default {
    props: {
      includeTypes: {type: Boolean, default: false},
      coverageAreas: {type: Array, default: () => []},
      filtering: {type: Boolean, default: false}
    },
    data() {
      return {
        researchTypes: [
          {name: 'Company Profiles', id: 'profiles'},
          {name: 'Research Reports', id: 'reports'},
          {name: 'Research Briefs', id: 'insights'},
          {name: 'Case Studies', id: 'case_studies'},
          {name: 'Tech Hubs', id: 'technologies'},
          {name: 'News Commentaries', id: 'news'},
        ],
        checkedTypes: [],
        checkedAreas: [],
        popoverVisible: this.filtering
      }
    },
    computed: {
      serviceAreas: function() {
        return this.coverageAreas.filter(ca => ca.type == 'service')
      },
      programAreas: function() {
        return this.coverageAreas.filter(ca => ca.type == 'program')
      },
      nonProgramAreas: function() {
        return this.coverageAreas.filter(ca => !ca.type)
      },
      filterParams: function() {
        let typeFilters = this.includeTypes && this.checkedTypes.length
          ? this.checkedTypes.map(rt => `&research_types[]=${rt}`).join('')
          : ''
        let areaFilters = this.checkedAreas.length
          ? this.checkedAreas.map(ca => `&coverage_areas[]=${ca}`).join('')
          : ''
        return `${typeFilters}${areaFilters}`
      }
    },
    methods: {
      setFilterParams() {
        this.popoverVisible = false
        this.$emit('update-filters', this.filterParams)
      },
      closePopover() {
        this.popoverVisible = false
      }
    }
  }
</script>

<style lang="scss">
  .filter-popover {
    .el-row {
      min-width: 522px;
    }
    .filter-header {
      font-weight: 600;
      text-transform: uppercase;
    }
    .filter-subheader {
      font-weight: 300;
      text-transform: uppercase;
    }
    .filter-checkboxes {
      border: 1px solid #e2e2e2;
      height: 200px;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 10px;
      margin: 10px 0;
      label {
        width: 90%;
      }
    }
  }
</style>
