import { render, staticRenderFns } from "./RecommendedResearch.vue.erb?vue&type=template&id=6410616a&"
import script from "./RecommendedResearch.vue.erb?vue&type=script&lang=js&"
export * from "./RecommendedResearch.vue.erb?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports