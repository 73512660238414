<template>
  <el-card class="research-item">
    <el-row class="hidden-md-and-up">
      <el-col class="importance-take text-right">
        <span :class="`tooltip-like-label ${item.importance.slug}`">
          {{ item.importance.name }}
        </span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="18" :lg="19">
        <el-row v-if="item.source" class="item-source">
          <el-col>
            <i class="el-icon-location" /> Because
            {{ item.source.type == 'search' ? 'of your saved search for' : 'you follow' }}
            <a :href="item.source.href">{{ item.source.name }}</a>:
          </el-col>
        </el-row>
        <el-row v-else-if="item.topics" class="item-source">
          <el-col>
            <i class="el-icon-location" /> Because of your reading history in
            <a :href="item.topics.href">{{ item.topics.name }}</a>
            <span v-if="item.topics.others">
              and more
              <el-tooltip :content="item.topics.others">
                <i class="el-icon-info"></i>
              </el-tooltip>
            </span>:
          </el-col>
        </el-row>
        <el-row>
          <el-col class="item-header">
            <div v-if="item.logo" class="item-logo">
              <el-image :src="item.logo" class="item-logo-image">
                <div slot="error" class="image-slot">
                  <el-avatar icon="el-icon-picture-outline" />
                </div>
              </el-image>
            </div>
            <div class="item-info">
              <a class="item-title" :href="item.href">{{ item.title }}</a><br>
              <template v-if="item.authors && item.authors.length > 0">
                <span>By <strong v-for="(author, index) in item.authors">
                  <a v-if="author.link" :href="author.link">{{ author.name }}</a><span v-else>{{ author.name }}</span><span v-if="index + 1 < item.authors.length">, </span>
                </strong></span>
                <br v-if="item.logo"><span v-else> | </span>
              </template>
              <span>Last Updated on {{ item.date }}</span>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col
        :md="5"
        :lg="4"
        :push="1"
        class="importance-take text-right hidden-sm-and-down"
      >
        <span :class="`tooltip-like-label ${item.importance.slug}`">
          {{ item.importance.name }}
        </span>
      </el-col>
    </el-row>
    <el-row class="item-description">
      <el-col>
        <span>{{ item.description }}</span>
        <a :href="item.href">read more</a>.
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
  export default {
    props: {
      item: {type: Object, default: () => {}},
    }
  }
</script>

<style lang="scss">
  @import "app/assets/stylesheets/common";

  .research-item {
    margin: 15px 0;
    box-shadow: 0 0 5px #888;

    a { color: $med-blue; }
    .item-header { display: flex; }
    .item-description { margin-top: 10px;}
    .tooltip-like-label {
      margin-right: -20px; font-size: 12px;

      &.news-commentary { border-left-color: $lux-blue; }
    }

    .item-source {
      border-bottom: 1px solid #f5f5f5;
      padding-bottom: 5px;
      margin-bottom: 10px;
      color: #5d5d5d;
      font-size: 12px;
    }

    .item-logo {
      border-right: 1px solid #f5f5f5;
      padding-right: 10px;
      margin-right: 10px;
      display: flex;

      .item-logo-image {
        height: 100%;
        height: 60px;
        width: 60px;
        position: relative;

        img {
          height: auto;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        .image-slot {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .item-info {

      color: #5d5d5d;
      font-size: 12px;

      .item-title { font-size: 18px; font-weight: 700; }
    }
  }
</style>
