

<template>
  <div class="recent-research">
    <div v-if="!initialLoadComplete" class="text-center">
      <img src="https://staging.luxresearchinc.com/assets/render_async_preloader-52243b3c41782a6e3a15630546defb42df510748a9126bdd24334f9a30ad55bd.gif" />
    </div>
    <div v-else>
      <filter-popover
        v-if="total > 10 || filterParams.length"
        include-types
        :coverage-areas="coverageAreas"
        :filtering="filtering"
        @update-filters="updateFilters"
        @toggle-popover="togglePopover"
      >
        <span>Filter <i class="el-icon-caret-bottom"></i></span>
      </filter-popover>
      <div v-if="!updateComplete" class="text-center">
        <img src="https://staging.luxresearchinc.com/assets/render_async_preloader-52243b3c41782a6e3a15630546defb42df510748a9126bdd24334f9a30ad55bd.gif" />
      </div>
      <template v-else>
        <div v-if="recent.length">
          <research-item
            v-for="item in recent"
            :key="item.title"
            :item="item"
          />
        </div>
        <div v-else>
          <p class="keywords"><strong>We're sorry, there are no results for these filters.</strong></p>
        </div>
      </template>
      <el-pagination
        :total="total"
        :current-page.sync="page"
        layout="prev,pager,next"
        prev-text="← Previous"
        next-text="Next →"
        class="research-item-pagination"
        hide-on-single-page
        @current-change="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
  import ResearchItem from './ResearchItem'
  import FilterPopover from './FilterPopover'

  const url = "/home/recent.json"

  export default {
    components: {
      ResearchItem,
      FilterPopover
    },
    data() {
      return {
        initialLoadComplete: false,
        updateComplete: false,
        recent: [],
        total: 0,
        page: 1,
        coverageAreas: [],
        filterParams: '',
        filtering: false
      }
    },
    created() {
      fetch(url)
        .then(res => res.json())
        .then(data => {
          this.recent = data.recent
          this.total = data.total
          this.coverageAreas = data.coverage_areas
          this.updateComplete = true
          this.initialLoadComplete = true
        })
    },
    methods: {
      handlePageChange() {
        fetch(`${url}?page=${this.page}${this.filterParams}`)
          .then(res => res.json())
          .then(data => {
            this.recent = data.recent
            this.total = data.total
            this.updateComplete = true
          })
          .finally(() => document.body.scrollIntoView({behavior: 'smooth'}))
      },
      updateFilters(params) {
        this.filterParams = params
        this.page = 1
        this.updateComplete = false
        this.handlePageChange()
      },
      togglePopover() {
        this.filtering = !this.filtering
      }
    }
  }
</script>

<style lang="scss">
  @import '../research_pagination';
</style>
