/* rails-erb-loader-dependencies controllers/home_controller */




<template>
  <div class="containerHolder">
    <el-main class="home-main container">
      <el-row :gutter="40">
        <el-col v-if="currentUser && showAccount" :span="5" class="hidden-sm-and-down">
          <announcements />
          <my-account
            ref="myAccount"
            :showInquiry="showInquiry"
            @hide="() => showAccount = false"
            @step-ready="() => { stepsReady++ }"
          />
        </el-col>
        <el-col :span="24" :md="currentUser && showAccount ? 12 : 17">
          <research-lists
            ref="researchLists"
            :user="currentUser"
            @step-ready="() => { stepsReady++ }"
          />
        </el-col>
        <el-col :span="24" :md="7" class="left-column">
          <template v-if="currentUser">
            <les-banner :user="currentUser"/>
            <webinar v-if="webinar && webinar.title" v-bind=webinar />
            <featured-updates :user="currentUser" />
            <top-hits />
            <upcoming />
          </template>
          <div v-else class="banner-cont">
            <a :href="loggedOut.href" target="_blank">
              <img class="banner-img" :src="loggedOut.src" />
            </a>
          </div>
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
  import {checkResponse} from 'labs-js/src/common/utils'
  import MyAccount from './MyAccount'
  import ResearchLists from './ResearchLists'
  import Webinar from './Webinar'
  import TopHits from './TopHits'
  import FeaturedUpdates from './FeaturedUpdates'
  import Upcoming from './Upcoming'
  import LesBanner from './LesBanner'
  import Announcements from './Announcements'

  const TotalSteps = 3

  export default {
    components: {
      MyAccount,
      ResearchLists,
      Webinar,
      TopHits,
      FeaturedUpdates,
      Upcoming,
      LesBanner,
      Announcements
    },
    props: {
      currentUser: {type: String, default: ''},
      showTour: {type: Boolean, default: false},
      showInquiry: {type: Boolean, default: false}
    },
    data() {
      return {
        showAccount: true,
        stepsReady: 0,
        webinar: null,
        loggedOut: {src: 'https://staging.luxresearchinc.com/assets/homepage/logged-out-lms-banner-3e109ecb5f705a3c37f2b5b973ca2d62177e3e66cdc50cc9f61b602ddae07d61.png', href: 'https://www.luxresearchinc.com/contact-us'},
      }
    },
    watch: {
      stepsReady(steps) {
        if (window.innerWidth > 991 && steps == TotalSteps && this.showTour) {
          this.$nextTick(() => {
            const tour = this.$shepherd({
              useModalOverlay: true,
              defaultStepOptions: {
                classes: 'homepage-tour-step',
                modalOverlayOpeningPadding: 10,
                scrollTo: true,
                scrollToHandler: elem => {
                  const top = elem.getBoundingClientRect().top + window.pageYOffset
                  window.scrollTo({
                    top: top - 25, // padding and offset
                    behavior: 'smooth'
                  })
                },
                cancelIcon: {
                  enabled: true,
                  label: 'Close',
                },
              }
            })

            const tourComplete = () => {
              fetch("/home.json?complete=now")
                .then(res => res.json())
                .then(data => { if (data.success == false) alert(data.message) })
                .finally(() => { this.$el.scrollIntoView({behavior: 'smooth'}) })
            }

            tour.on('complete', tourComplete)
            tour.on('cancel', tourComplete)

            const buttons = [
              {
                text: 'Next',
                classes: 'btn-luxButton',
                action: tour.next
              }
            ]

            tour.addSteps([
              {
                attachTo: {
                  element: this.$refs['researchLists'].$refs['researchPreamble'],
                  on: 'bottom'
                },
                title: 'NEW: RECOMMENDED JUST FOR YOU',
                text: 'Your new personalized homepage feed is based on your specific interests. Just keep reading Lux content, follow companies and technologies, and used saved searches, and this personalized homepage will get better and better for you.',
                buttons
              },
              {
                attachTo: {
                  element: this.$refs['myAccount'].$refs['myResearch'],
                  on: 'right'
                },
                title: 'YOUR ACCOUNT AT A GLANCE',
                text: 'Get a snapshot of everything in your Lux account in the new My Lux column. This includes inquiries as well as new functionality to jump back into your reading history and search history.',
                buttons
              },
              {
                attachTo: {
                  element: this.$refs['myAccount'].$refs['myTeam'],
                  on: 'right'
                },
                title: 'IT\'S EASY TO CONTACT US',
                text: 'Need to get in touch? Check out the account team section to see who your engagement manager and account manager is.',
                buttons: [
                  {
                    text: 'Done',
                    classes: 'btn-luxButton',
                    action: tour.next
                  }
                ]
              }
            ])

            tour.start()
          })
        }
      }
    },
    created() {
      fetch("/home/webinar.json")
        .then(checkResponse)
        .then(data => this.webinar = data)
    }
  }
</script>

<style lang="scss">
  @import "app/assets/stylesheets/common";

  .secondary-header {
    padding: 0;
  }

  .btn-luxButton {
    color: #fff;
    background-color: $lux-blue;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 13px;
    border: none;

    &:hover, &:focus {
      color: #fff;
      background-color: lighten( $lux-blue, 10% );
      border: none;
    }

    a {
      color: #fff;
      text-decoration: none;
    }
  }

  .home-main {

    &.container {
      padding-top: 40px;
    }

    .el-col > div:first-child .heading:first-child {
      margin-top: 0;
      padding-top: 0;
    }

    .banner-cont {
      max-width: 300px;
      width: 100%;
      position: relative;
      margin-bottom: 0;
      padding-bottom: 0;

      .banner-img {
        width: 100%;
        height: 100%;
      }
    }

    .heading {
      color: $lux-blue;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;
      padding-top: 12px;
      margin-top: 40px;
    }

    .el-divider {
      margin: 6px 0;
    }
  }

  .homepage-tour-step {
    background-color: #d2e9f1;
    padding: 0 20px;
    footer { background: none; }

    .shepherd-arrow::before { background-color: #d2e9f1 !important; }
    .shepherd-content {
      .shepherd-header {
        padding-left: 10.5px;
        padding-bottom: 0;

        &, .shepherd-title {
          background-color: #d2e9f1;
          font-size: 16px;
          font-weight: 700;
        }
      }
      .shepherd-text { font-size: 14px; }
    }
  }

  .shepherd-modal-overlay-container { opacity: 0.7; }
</style>
