import '@babel/polyfill'
import 'whatwg-fetch'
import 'labs-js/src/common/custom_polyfills.js'
import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import VueShepherd from 'vue-shepherd'
import 'shepherd.js/dist/css/shepherd.css'
import locale from 'element-ui/lib/locale/lang/en'
import Home from '../src/home/components/App'

const element = document.getElementById('home_mount')
const tour = element.dataset.tour == 'true'
const inquiry = element.dataset.inquiry == 'true'

Vue.use(ElementUI, {locale})
Vue.use(VueShepherd)
Vue.filter('capitalize', function(value) {
  if (!value) return ''
  value = value.toString()
  return value.split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ')
})

new Vue({
  el: element,
  components: {Home},
  template: `<Home current-user="${element.dataset.user}" :show-tour="${tour}" :show-inquiry="${inquiry}" />`,
})
