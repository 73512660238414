
<template>
  <div v-if="announcements.length > 0" class="announcements-banner">
    <h3 class="heading">Announcements</h3>
    <el-divider />
    <a
      v-for="(announcement, idx) in announcements"
      :key="idx"
      :href="announcement.href"
      target="_blank"
      class="announcements-item"
    >
      <img class="featured-slider-bg" :src="announcement.src">
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      announcements: [
        {
          href: 'https://www.motivbase.com/post/the-desperate-need-for-measurable-foresight',
          src: 'https://staging.luxresearchinc.com/assets/promotions/explore_motivebase-28d60efb9d7a7b7eb6c2e7045005728aeb6cd8b527e3f062f87d9e6ddc1174c8.jpg'
        },
        {
          href: 'https://www.luxresearchinc.com/events-and-webinars/webinars/what-does-sustainability-really-mean-to-the-consumer-in-the-context-of-cpg-products/',
          src: 'https://staging.luxresearchinc.com/assets/promotions/On-Demand_Motivbase_Webinar-2d3ccc92967612b843abc7b3186f43818d7463c76abe04f9d13a994199b93a53.jpg'
        },
      ]
    }
  }
}
</script>

<style lang="scss">
  .announcements-banner {
    .announcements-item {
      img {width: 100%;}
      &:not(:first-of-type) img {margin-top: 20px;}
    }
  }
</style>
