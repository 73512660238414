
<template>
  <div class="home-upcoming">
    <div>
      <h3 class="heading">Upcoming Client Only Webinars</h3>
      <el-divider />
      <div class="home-webinars">
        <el-row v-for="webinar in webinars" :key="webinar.title" class="webinar-item">
          <el-col>
            <p class="webinar-date">{{ webinar.date }}</p>
            <h4>
              <a :href="webinar.href">
                {{ webinar.title }}
              </a>
            </h4>
          </el-col>
        </el-row>
        <el-button size="medium" class="btn-luxButton">
          <a href="/webinars">View All Webinars</a>
        </el-button>
      </div>
    </div>
    <div>
      <h3 class="heading">Upcoming Industry Events</h3>
      <el-divider />
      <div class="home-events">
        <el-row v-for="ev in events" :key="ev.name" class="event-item">
          <el-col>
            <div class="event-name"><a :href="ev.link" target="_blank">{{ ev.name }}</a></div>
            <span class="event-info">{{ ev.location }} | {{ ev.date_range }}</span>
            <div v-if="ev.attendees.length || ev.speakers.length" class="event-attendees">
              <span v-for="attendee in ev.attendees">
                <a :href="attendee.link">{{ attendee.name }}</a><br>
              </span>
              <span v-for="speaker in ev.speakers">
                <a :href="speaker.link">{{ speaker.name }}</a> (speaker)<br>
              </span>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        webinars: [],
        events: []
      }
    },

    created() {
      fetch("/home/upcoming.json")
        .then(res => res.json())
        .then(data => {
          this.webinars = data.webinars
          this.events = data.events
        })
    }
  }
</script>

<style lang="scss">
  .home-upcoming {
    .webinar-item {
      margin-bottom: 20px;

      h4 {
        font-size: 14px;
        margin: 0;
      }

      .webinar-date {
        text-transform: uppercase;
        font-weight: 400;
        margin-bottom: 0;
        font-size: 14px;
      }
    }

    .event-item{
      color: #7c7c7c;
      margin-bottom: 20px;

      .event-name {
        font-weight: bold;
        color: #5d5d5d;
        a {font-weight: bold;}
      }
      .event-info { font-weight: 100; }
      .event-attendees {font-weight: 500; }
    }
  }
</style>
