
<template>
  <div class="les-banner" v-if="updates.length>0">
    <h3 class="heading">Lux Executive Summit 2021</h3>
    <el-divider />
    <el-carousel trigger="click" :autoplay="false" indicator-position="outside">
      <el-carousel-item v-for="(update, idx) in updates" v-if="user || !update.requiresUser" :key="idx">
        <div class="banner-cont">
          <a :href="update.href" target="_blank">
            <img class="banner-img" :src="update.src" />
          </a>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>

  export default {
    props: {
      user: {type: String, default: ''}
    },
    data() {
      return {
        updates: []
      }
    }
  }
</script>

<style lang="scss">
  @import "app/assets/stylesheets/common";

  .les-banner {

    .el-carousel {
      max-width: 300px;

      .el-carousel__container {
        @media (max-width: 1200px) {
          height: 243px;
        }
        @media (max-width: 991px) {
          height: 300px;
        }
      }
    }

    .banner-cont {
      max-width: 300px;
      width: 100%;
      position: relative;
      margin-bottom: 0;
      padding-bottom: 0;

      .banner-img {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
